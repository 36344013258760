import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import answersReducer from '@/features/answers/answersSlice';
import assessmentRequestsReducer from '@/features/assessmentRequests/assessmentRequestsSlice';
import assessmentsReducer from '@/features/assessments/assessmentsSlice';
import authReducer from '@/features/auth/authSlice';
import chatReducer from '@/features/chat/chatSlice';
import globalReducer from '@/features/global/globalSlice';
import questionsReducer from '@/features/questions/questionsSlice';
import companiesReducer from '@/features/admin/companies/adminCompaniesSlice';
import topicsReducer from '@/features/topics/topicsSlice';
import usersReducer from '@/features/users/usersSlice';
import { AppDispatch } from '@/types/AppDispatch';
import { RootState } from '@/types/RootState';
import { companyInfoApi } from '@/app/services/companyInfo';

import { answersApi } from './services/answers';
import { assessmentRequestsApi } from './services/assessmentRequests';
import { assessmentsApi } from './services/assessments';
import { authApi } from './services/auth';
import { awsApi } from './services/aws';
import { chatApi } from './services/chat';
import { questionsApi } from './services/questions';
import { companiesApi } from './services/companies';
import { topicsApi } from './services/topics';
import { usersApi } from './services/users';
import listenerMiddleware from './middleware/listenerMiddleware';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [assessmentsApi.reducerPath]: assessmentsApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [answersApi.reducerPath]: answersApi.reducer,
    [questionsApi.reducerPath]: questionsApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [topicsApi.reducerPath]: topicsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [assessmentRequestsApi.reducerPath]: assessmentRequestsApi.reducer,
    [awsApi.reducerPath]: awsApi.reducer,
    [companyInfoApi.reducerPath]: companyInfoApi.reducer,
    auth: authReducer,
    assessments: assessmentsReducer,
    chat: chatReducer,
    answers: answersReducer,
    questions: questionsReducer,
    companies: companiesReducer,
    topics: topicsReducer,
    users: usersReducer,
    assessmentRequests: assessmentRequestsReducer,
    global: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      assessmentsApi.middleware,
      topicsApi.middleware,
      questionsApi.middleware,
      companiesApi.middleware,
      answersApi.middleware,
      usersApi.middleware,
      awsApi.middleware,
      chatApi.middleware,
      assessmentRequestsApi.middleware,
      listenerMiddleware.middleware,
      companyInfoApi.middleware,
    ),
});

// typed hooks
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
