import { datadogLogs } from '@datadog/browser-logs';
import { createLogger, format, transports } from 'winston';

import { isClient } from '@/utils/isClient';
import { utilities as nestWinstonModuleUtilities } from '@/utils/logger.utils';

const isDevelopment = process.env.NODE_ENV === 'development';
const isDatadogDisabled = process.env.NEXT_PUBLIC_DD_DISABLE === 'true';

function initBrowserLogs() {
  if (!process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) {
    throw new Error(
      'Datadog client token is mandatory! Make sure to set it up or change NODE_ENV to "development"',
    );
  }
  if (datadogLogs.getInternalContext()) {
    return;
  }
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

function initServerLogs() {
  return createLogger({
    level: 'info',
    format: format.combine(format.timestamp(), nestWinstonModuleUtilities.format.nestLike()),
    transports: [
      new transports.Console({
        handleExceptions: true,
      }),
    ],
  });
}

const clientLogger = isDevelopment || isDatadogDisabled ? console : datadogLogs.logger;

const isDatadogLogger = Object.hasOwn(clientLogger, 'level');

if (isClient && isDatadogLogger) {
  initBrowserLogs();
}

// Available methods are: debug | info | warn | error
const logger = isClient ? clientLogger : initServerLogs();

export { logger };
