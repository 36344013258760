import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { NormalizedSchema } from 'normalizr';

import { IAssessmentRequest } from '@/interfaces';
import { RootState } from '@/types/RootState';
import { TypeToKey } from '@/types/TypeToKey';

import { clearCredentials } from '../auth/authSlice';

export type AssessmentRequestsState = {
  ids: string[] | [];
  entities: TypeToKey<IAssessmentRequest>;
  currentAssessmentRequestId: string | null;
};

const initialState = {
  ids: [],
  entities: {
    assessmentRequests: {},
    assessments: {},
    companies: {},
    providers: {},
    products: {},
    topics: {},
    esgManagementActivities: {},
  },
  currentAssessmentRequestId: null,
} as AssessmentRequestsState;

const slice = createSlice({
  name: 'assessmentRequests',
  initialState,
  reducers: {
    setAssessmentRequests: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IAssessmentRequest>, string[]>>,
    ) => {
      state.ids = payload.result;
      state.entities = payload.entities;
      state.currentAssessmentRequestId =
        payload.result && payload.result.length > 0 ? payload.result[0] : null;
    },
    setAssessmentRequest: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IAssessmentRequest>, string[]>>,
    ) => {
      const newState = merge({}, state, {
        entities: payload.entities,
        ids: payload.result,
      });
      state.ids = newState.ids;
      state.entities = newState.entities;
      state.currentAssessmentRequestId = newState.ids[0];
    },
    setCurrentAssessmentRequest: (state, { payload }: PayloadAction<string>) => {
      state.currentAssessmentRequestId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearCredentials, () => {
      return initialState;
    });
  },
});

export const { setAssessmentRequests, setAssessmentRequest, setCurrentAssessmentRequest } =
  slice.actions;

export default slice.reducer;

export const selectProviders = (state: RootState) => state.assessmentRequests.entities.providers;
export const selectCompanies = (state: RootState) => state.assessmentRequests.entities.companies;
export const selectProducts = (state: RootState) => state.assessmentRequests.entities.products;
export const selectAssessmentRequestsState = (state: RootState) => state.assessmentRequests;
export const selectAssessmentRequests = (state: RootState) =>
  state.assessmentRequests.entities.assessmentRequests;
export const selectEsgManagementActivities = (state: RootState) =>
  state.assessmentRequests.entities.esgManagementActivities;
