import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useSelector } from 'react-redux';

import { useLogoutMutation } from '@/app/services/users';
import { useAppDispatch } from '@/app/store';
import { setErrors, setHasUnsavedData, setValidationActive } from '@/features/answers/answersSlice';
import { clearCredentials } from '@/features/auth/authSlice';
import {
  changeLocation,
  hideConfirmationDialog,
  selectConfirmationState,
} from '@/features/global/globalSlice';
import {
  setCurrentQuestionId,
  setIsSubmissionConfirmationOpen,
} from '@/features/questions/questionsSlice';
import { setCurrentTopicId } from '@/features/topics/topicsSlice';

const ConfirmationDialog = () => {
  const t = useTranslations('ConfirmationDialog');
  const tg = useTranslations('Generic');
  const dispatch = useAppDispatch();

  const { open, title, content, onConfirm } = useSelector(selectConfirmationState);

  const [logout] = useLogoutMutation();

  const handleCancel = () => {
    dispatch(hideConfirmationDialog());
  };

  const handleConfirm = async () => {
    if (onConfirm) {
      if (onConfirm['type'] === 'topics/setCurrentTopicId') {
        dispatch(setCurrentTopicId(onConfirm['payload']));
      } else if (onConfirm['type'] === 'questions/setCurrentQuestionId') {
        dispatch(setCurrentQuestionId(onConfirm['payload']));
      } else if (onConfirm['type'] === 'questions/setIsSubmissionConfirmationOpen') {
        dispatch(setIsSubmissionConfirmationOpen(onConfirm['payload']));
      } else if (onConfirm['type'] === 'auth/clearCredentials') {
        await logout().unwrap();
        dispatch(clearCredentials());
        dispatch(changeLocation({ path: '/login', locale: onConfirm['payload'] }));
      } else if (onConfirm['type'] === 'global/changeLocation') {
        dispatch(changeLocation(onConfirm['payload']));
      }
      dispatch(setErrors({}));
      dispatch(setValidationActive(false));
      dispatch(setHasUnsavedData(false));
      dispatch(hideConfirmationDialog());
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{title ? t(title) : ''}</DialogTitle>
      <DialogContent>{content ? t(content) : ''}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{tg('yes')}</Button>
        <Button onClick={handleConfirm}>{tg('no')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
