import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/types/RootState';

import { clearCredentials } from '../auth/authSlice';

type confirmationDialogTitleType = 'unsavedDataTitle' | null;
type confirmationDialogContentType = 'unsavedDataContent' | null;

type GlobalState = {
  confirmation: {
    open: boolean;
    title: confirmationDialogTitleType;
    content: confirmationDialogContentType;
    onConfirm: AnyAction | null;
  };
  location: {
    path: string;
    locale: string;
  } | null;
};

const initialState = {
  confirmation: {
    open: false,
    title: null,
    content: null,
    onConfirm: null,
  },
  location: null,
} as GlobalState;

export const logout = createAction('user/logout');

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    showConfirmationDialog: (
      state,
      {
        payload,
      }: PayloadAction<{
        title: confirmationDialogTitleType;
        content: confirmationDialogContentType;
        onConfirm: AnyAction;
      }>,
    ) => {
      state.confirmation = {
        open: true,
        title: payload.title,
        content: payload.content,
        onConfirm: payload.onConfirm,
      };
    },
    hideConfirmationDialog: (state) => {
      state.confirmation = initialState.confirmation;
    },
    changeLocation: (
      state,
      { payload }: PayloadAction<{ path: string; locale: string } | null>,
    ) => {
      state.location = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearCredentials, () => {
      return initialState;
    });
  },
});

export const selectConfirmationState = (state: RootState) => state.global.confirmation;
export const selectLocation = (state: RootState) => state.global.location;

const { actions, reducer } = slice;

export const { showConfirmationDialog, hideConfirmationDialog, changeLocation } = actions;

export default reducer;
