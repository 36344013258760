import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { NormalizedSchema } from 'normalizr';

import { IAssessment } from '@/interfaces';
import { AssessmentInsights } from '@/types/AssessmentInsights';
import { RootState } from '@/types/RootState';
import { TypeToKey } from '@/types/TypeToKey';

import { clearCredentials } from '../auth/authSlice';

export type AssessmentsState = {
  ids: string[] | [];
  entities: TypeToKey<IAssessment>;
  currentAssessmentId: string | null;
  currentAssessmentRequestRelationId: string | null;
  assessmentSummary: TypeToKey<AssessmentInsights>;
};

const initialState = {
  ids: [],
  entities: {
    assessments: {},
    assessmentRequests: {},
    companies: {},
    products: {},
    answers: {},
    questions: {},
    choices: {},
    topics: {},
    esgManagementActivities: {},
  },
  currentAssessmentId: null,
  assessmentSummary: {
    topics: {},
    processes: {},
    assessmentSummary: {},
  },
} as AssessmentsState;

const slice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    setAssessments: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IAssessment>, string[]>>,
    ) => {
      state.ids = payload.result;
      state.entities = payload.entities;
    },
    setAssessment: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IAssessment>, string[]>>,
    ) => {
      const newState = merge({}, state, {
        entities: payload.entities,
        ids: payload.result,
      });
      state.ids = newState.ids;
      state.entities = newState.entities;
    },
    setCurrentAssessment: (state, { payload }: PayloadAction<string>) => {
      state.currentAssessmentId = payload;
    },
    setAssessmentSummary: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<AssessmentInsights>, string[]>>,
    ) => {
      state.assessmentSummary = payload.entities;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearCredentials, () => {
      return initialState;
    });
  },
});

export const { setAssessments, setAssessment, setCurrentAssessment, setAssessmentSummary } =
  slice.actions;

export default slice.reducer;

export const selectCompanies = (state: RootState) => state.assessments.entities.companies;
export const selectProducts = (state: RootState) => state.assessments.entities.products;
export const selectAssessments = (state: RootState) => state.assessments.entities.assessments;
export const selectAssessmentSummaryTopics = (state: RootState) =>
  state.assessments.assessmentSummary.topics;
export const selectAssessmentRequests = (state: RootState) =>
  state.assessments.entities.assessmentRequests;
export const selectCurrentAssessment = (state: RootState) => {
  if (state.assessments.currentAssessmentId) {
    return state.assessments.entities.assessments?.[state.assessments.currentAssessmentId];
  }
  return null;
};
export const selectCurrentAssessmentRequest = createSelector(
  selectCurrentAssessment,
  selectAssessmentRequests,
  (currentAssessment, assessmentRequests) => {
    if (currentAssessment && assessmentRequests && currentAssessment.assessmentRequest) {
      return assessmentRequests[currentAssessment.assessmentRequest];
    }
  },
);
export const selectCurrentRequesterCompany = createSelector(
  selectCurrentAssessmentRequest,
  selectCompanies,
  (currentAssessmentRequest, companies) => {
    if (currentAssessmentRequest) {
      return companies[currentAssessmentRequest.company];
    }
  },
);
export const selectAssessmentAnswers = (state: RootState) => state.assessments.entities.answers;
export const selectAssessmentQuestions = (state: RootState) => state.assessments.entities.questions;
export const selectAssessmentChoices = (state: RootState) => state.assessments.entities.choices;
export const selectEsgManagementActivities = (state: RootState) =>
  state.assessments.entities.esgManagementActivities;
