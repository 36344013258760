import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IUser } from '@/interfaces';
import { RootState } from '@/types/RootState';

export interface AuthResponse {
  user: IUser;
  token: string;
}

export interface LoginDto {
  email: string;
  password: string;
  token?: string;
}

export interface SignUpDto {
  companyName: string;
  companyRegNum: string;
  companyNaceCode: string;
  companyIndustryCategory: string;
  companyIndustrySubcategory: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: string;
  password: string;
  passwordConfirmation: string;
  locale?: string;
  token?: string | undefined;
}

export type RequestPasswordResetDto = {
  email: string | null;
  locale: string | undefined;
  callbackUrl?: string;
};

export type ResetPasswordDto = {
  token: string;
  password: string;
  passwordConfirmation: string;
  locale: string;
  callbackUrl?: string;
};

export const authApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, LoginDto>({
      query: (body) => ({
        url: '/auth/signin',
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),
    signUp: builder.mutation<unknown, Partial<SignUpDto>>({
      query: (body) => ({
        url: '/auth/signup',
        method: 'POST',
        body,
      }),
    }),
    verifyUser: builder.mutation<void, { token: string; locale: string }>({
      query: ({ token, locale }) => ({
        url: `/auth/signup/verification?token=${token}&locale=${locale}`,
        method: 'POST',
        body: {},
      }),
    }),
    requestPasswordReset: builder.mutation<void, RequestPasswordResetDto>({
      query: (body) => ({
        url: '/auth/request-password-reset',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordDto>({
      query: (body) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body,
      }),
    }),
  }),
  reducerPath: 'authApi',
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useVerifyUserMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
} = authApi;
