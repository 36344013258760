import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NormalizedSchema } from 'normalizr';

import { ICompany } from '@/interfaces';
import { RootState } from '@/types/RootState';
import { TypeToKey } from '@/types/TypeToKey';

import { clearCredentials } from '../../auth/authSlice';

export type CompaniesState = {
  ids: string[] | [];
  entities: TypeToKey<ICompany>;
};

const initialState = {
  ids: [],
  entities: {
    companies: {},
  },
} as CompaniesState;

const slice = createSlice({
  name: 'adminCompanies',
  initialState,
  reducers: {
    setCompanies: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<ICompany>, string[]>>,
    ) => {
      state.ids = payload.result;
      state.entities = payload.entities;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearCredentials, () => {
      return initialState;
    });
  },
});

export const { setCompanies } = slice.actions;

export default slice.reducer;

export const selectAdminCompanies = (state: RootState) => state.companies.entities.companies;
