import { createApi } from '@reduxjs/toolkit/query/react';
import { NormalizedSchema } from 'normalizr';

import { IAnswer, IAnswerEdit } from '@/interfaces';
import { SupportedFileType, TypeToKey } from '@/types';
import { normalizeResponse } from '@/utils/normalize';

import { answersSchema } from '../schema';

import { baseQueryWithRefresh } from './base';

type GetPresignedUrlForUploadResponse = {
  documentId: string;
  fileName: string;
  extension: SupportedFileType;
  url: string;
};

export type AnswerUpdateDto = {
  insert?: IAnswerEdit[];
  update?: IAnswerEdit[];
  delete?: string[];
};

export const answersApi = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    fetchAnswers: builder.mutation<NormalizedSchema<TypeToKey<IAnswer>, string[]>, string>({
      query: (assessmentId) => ({
        url: `/answers?assessmentId=${assessmentId}`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAnswer[]) => {
        const data = normalizeResponse<IAnswer>(response, answersSchema);
        return data;
      },
    }),
    fetchAnswersForQuestion: builder.mutation<
      NormalizedSchema<TypeToKey<IAnswer>, string[]>,
      string
    >({
      query: (questionId) => ({
        url: `/answers?questionId=${questionId}`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAnswer[]) => {
        const data = normalizeResponse<IAnswer>(response, answersSchema);
        return data;
      },
    }),
    getPresignedUrlForUpload: builder.mutation<GetPresignedUrlForUploadResponse, { name: string }>({
      query: (payload) => ({
        url: '/answers/generate-presigned-url-for-upload',
        method: 'POST',
        credentials: 'include',
        body: payload,
      }),
    }),
    getPresignedUrlForDownload: builder.mutation<
      { url: string },
      { documentId: string; assessmentId?: string }
    >({
      query: (payload) => ({
        url: '/answers/generate-presigned-url-for-download',
        method: 'POST',
        credentials: 'include',
        body: payload,
      }),
    }),
    deleteDocument: builder.mutation<void, { id: string; name: string }>({
      query: ({ id, name }) => {
        return {
          url: `/answers/delete-document/${id}`,
          method: 'POST',
          credentials: 'include',
          body: {
            id,
            name,
          },
        };
      },
    }),
    createOrUpdateAnswers: builder.mutation<
      NormalizedSchema<TypeToKey<IAnswer>, string[]>,
      AnswerUpdateDto
    >({
      query: (body) => ({
        url: '/answers',
        method: 'POST',
        credentials: 'include',
        body,
      }),
      transformResponse: (response: IAnswer[]) => {
        const data = normalizeResponse<IAnswer>(response, answersSchema);
        return data;
      },
    }),
  }),
  reducerPath: 'answersApi',
});

export const {
  useFetchAnswersForQuestionMutation,
  useFetchAnswersMutation,
  useGetPresignedUrlForDownloadMutation,
  useGetPresignedUrlForUploadMutation,
  useDeleteDocumentMutation,
  useCreateOrUpdateAnswersMutation,
} = answersApi;
