import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NormalizedSchema } from 'normalizr';

import { ITopic } from '@/interfaces';
import { TypeToKey } from '@/types';
import { RootState } from '@/types/RootState';

type TopicsState = {
  ids: string[] | [];
  entities: TypeToKey<ITopic>;
  currentTopicId: string | null;
  showLastQuestion: boolean | null | undefined;
};

const slice = createSlice({
  name: 'topics',
  initialState: { ids: [], entities: {}, currentTopicId: null } as TopicsState,
  reducers: {
    setTopics: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<ITopic>, string[]>>,
    ) => {
      state.ids = payload.result;
      state.entities = payload.entities;
      state.currentTopicId = payload.result?.length > 0 ? payload.result[0] : null;
    },
    setCurrentTopicId: (
      state,
      { payload }: PayloadAction<{ topicId: string; showLastQuestion?: boolean | null }>,
    ) => {
      state.currentTopicId = payload.topicId;
      state.showLastQuestion = payload.showLastQuestion;
    },
    setShowLastQuestion: (state, { payload }: PayloadAction<boolean>) => {
      state.showLastQuestion = payload;
    },
  },
});

export const { setTopics, setCurrentTopicId, setShowLastQuestion } = slice.actions;

export default slice.reducer;

export const selectTopicsState = (state: RootState) => state.topics;
export const selectTopics = (state: RootState) => state.topics.entities.topics;
export const selectCurrentTopicId = (state: RootState) => state.topics.currentTopicId;
export const selectShowLastQuestion = (state: RootState) => state.topics.showLastQuestion;
export const selectCurrentTopic = (state: RootState) => {
  if (state.topics.currentTopicId) {
    return state.topics.entities.topics[state.topics.currentTopicId];
  }
  return null;
};
