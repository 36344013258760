import { TypedStartListening, createListenerMiddleware } from '@reduxjs/toolkit';

import { addAutosaveAnswersListener } from '@/features/answers/answersSlice';
import { AppDispatch } from '@/types/AppDispatch';
import { RootState } from '@/types/RootState';

const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

addAutosaveAnswersListener(listenerMiddleware.startListening);

export default listenerMiddleware;
