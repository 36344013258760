import { useEffect } from 'react';

import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import 'dayjs/locale/et';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { GetStaticPropsContext } from 'next';
import { NextIntlClientProvider } from 'next-intl';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SnackbarProvider } from 'notistack';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { Provider } from 'react-redux';

import { store } from '@/app/store';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import { logPageView } from '@/lib/gtag';
import { CurrentUserProvider } from '@/providers/CurrentUserProvider';
import { isClient } from '@/utils/isClient';

import createEmotionCache from '../createEmotionCache';
import '../styles/main.scss';
import theme from '../theme';

import { NextPageWithLayout } from './page';
import '/node_modules/flag-icons/css/flag-icons.min.css';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface AppPropsWithLayout extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

if (isClient) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.i.posthog.com',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') {
        posthog.debug();
      }
    },
  });
}

export default function App(props: AppPropsWithLayout) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const getLayout = Component.getLayout ?? ((page) => page);

  const { events, locale, defaultLocale } = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      logPageView(url);
      posthog?.capture('$pageview');
    };
    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  return (
    <CacheProvider value={emotionCache}>
      <Provider store={store}>
        <PostHogProvider client={posthog}>
          <Head>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
            <link rel="manifest" href="/favicon/site.webmanifest" />
            <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#fff"></meta>
          </Head>
          {process.env.NEXT_PUBLIC_APP_ENV === 'production' && (
            <>
              <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
                strategy="afterInteractive"
              />
              <Script id="google-analytics" strategy="afterInteractive">
                {`window.dataLayer = window.dataLayer || [];
                  function gtag(){window.dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');`}
              </Script>
            </>
          )}
          {process.env.NEXT_PUBLIC_APP_ENV === 'production' && (
            // eslint-disable-next-line
            <Script id="hotjar" strategy="beforeInteractive">
              {`(function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
            </Script>
          )}
          {pageProps.messages && (
            <NextIntlClientProvider
              messages={pageProps.messages}
              locale={locale || defaultLocale || 'en'}
            >
              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                  <CurrentUserProvider>
                    <CssBaseline />
                    {getLayout(<Component {...pageProps} />)}
                    <ConfirmationDialog />
                  </CurrentUserProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </NextIntlClientProvider>
          )}
        </PostHogProvider>
      </Provider>
    </CacheProvider>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: (await import(`../../messages/${locale}.json`)).default,
      locale,
    },
  };
}
